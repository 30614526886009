import { PLAYER_ADDED, PLAYER_UPDATE } from "./actionTypes"
import { Player, AddedPlayerAction, UpdatedPlayerAction } from "./types"

export const AddPlayer = (player: Player): AddedPlayerAction => ({
    type: PLAYER_ADDED,
    payload: { player }
})

export const UpdatePlayer = (player: Player): UpdatedPlayerAction => ({
    type: PLAYER_UPDATE,
    payload: { player }
})