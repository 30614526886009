import { set } from 'lodash/fp'
import { MATCH_ADDED, MATCH_UPDATE } from './actionTypes'
import { MatchActionTypes, MatchsState } from './types'

const initialState: MatchsState = {}

export default (state = initialState, action: MatchActionTypes) => {
  switch (action.type) {
    case MATCH_ADDED:
      return set(action.payload.match.id, action.payload.match, state)
    case MATCH_UPDATE:
      return set(action.payload.match.id, action.payload.match, state)
    default:
      return state
  }
}
