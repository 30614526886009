import React, { useEffect, useRef } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'

// Utils
import { useAuth0 } from './auth0'
import { PlayersDatabase } from './services/firebase'

// Redux
import { actions } from './features/players'

// Components
import { Navbar } from './components/NavBar/Navbar'
import { About } from './pages/About'
import { Home } from './pages/Home'
import { Player } from './features/players/types'

const App: React.FC = () => {
  const { loading, user, isAuthenticated } = useAuth0()
  const playersListener = useRef<null | any>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const registerNewPlayer = () => {
      // If we have no user then do nothing
      if (!isAuthenticated || !user) {
        return
      }

      // Check if player already exist
      PlayersDatabase.where('email', '==', user.email)
        .get()
        .then(function (snapshot) {
          // No player existed
          if (snapshot.size === 0) {
            const newPlayer: Player = {
              id: '(not set)',
              email: user.email,
              name: user.name,
              rating: 1000,
              picture: user.picture,
              matches: 0,
            }

            PlayersDatabase.add(newPlayer).then(function (ref) {
              ref.update({ id: ref.id })
            })
          }

          // We found a player with matching email
          snapshot.forEach(function (doc) {
            // Check if picture has changed if so update it
            if (doc.data().picture !== user.picture) {
              doc.ref.update({ picture: user.picture })
            }
          })
        })
    }

    const getPlayer = () => {
      PlayersDatabase.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          const player = {
            id: doc.id,
            name: doc.data().name,
            email: doc.data().email,
            rating: doc.data().rating,
            matches: doc.data().matches,
            picture: doc.data().picture,
          }
          dispatch(actions.AddPlayer(player))
        })
      })
    }

    const startPlayersListener = () => {
      playersListener.current = PlayersDatabase.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          const id = change.doc.id
          const data = change.doc.data()
          const player = {
            id: id,
            name: data.name,
            email: data.email,
            rating: data.rating,
            matches: data.matches,
            picture: data.picture,
          }

          if (change.type === 'added') {
            dispatch(actions.AddPlayer(player))
          }
          if (change.type === 'modified') {
            dispatch(actions.UpdatePlayer(player))
          }
          if (change.type === 'removed') {
            console.log('Removed player: ', id)
          }
        })
      })
    }

    registerNewPlayer()
    getPlayer()
    startPlayersListener()

    return () => {
      // Detach listener see https://firebase.google.com/docs/firestore/query-data/listen#detach_a_listener
      if (playersListener.current !== null) {
        playersListener.current()
      }
    }
  }, [dispatch, user, isAuthenticated])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
