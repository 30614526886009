import React from 'react'
import { NavLink } from 'react-router-dom'

// Auth0
import { useAuth0 } from '../../auth0';

// Styling
import './navbar.scss'

// Components
import { ProfileIcon } from 'components/ProfileIcon'
import { Button } from 'components/Button'

// Assets
import { ReactComponent as SoccerIcon } from '../../assets/soccer.svg';


export const Navbar: React.FC = () => {

  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleOnLogin = () => {
    loginWithRedirect({ redirect_uri: window.location.origin })
  }

  const handleOnLogout = () => {
    logout({ returnTo: window.location.origin })
  }
  
  return (
    <nav className={'nav-bar'}>
      <div className={'nav-bar__logo'}>
        <NavLink to="/" className={'nav-bar__logo-link'}>
          <SoccerIcon />{' '}
          <span className="nav-bar__title">Camelonta Fussball</span>
        </NavLink>
      </div>

      <div className={'nav-bar__actions'}>
        {!isAuthenticated && (
          <Button onClick={handleOnLogin} className={'btn--primary'}>
            Log in
          </Button>
        )}
        {isAuthenticated && (
          <Button onClick={handleOnLogout} className={'btn--subtle'}>
            Log out
          </Button>
        )}
        {isAuthenticated && <ProfileIcon />}
      </div>
    </nav>
  )
}