import { set } from 'lodash/fp'
import { PLAYER_ADDED, PLAYER_UPDATE } from './actionTypes'
import { PlayerActionTypes, PlayersState } from './types'

const initialState: PlayersState = {}

export default (state = initialState, action: PlayerActionTypes) => {
  switch (action.type) {
    case PLAYER_ADDED: 
      return set(action.payload.player.id, action.payload.player, state)
    case PLAYER_UPDATE:
      return set(action.payload.player.id, action.payload.player, state)
    default:
      return state
  }
}
