import { combineReducers, createStore } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension'

// Features
import { PlayersReducer } from 'features/players'
import { Player } from 'features/players/types'
import { MatchesReducer } from 'features/matches'
import { Match } from 'features/matches/types'

export interface AppStore {
  players: Player[]
  matches: Match[]
}

/* Create root reducer, containing all features of the application */
const rootReducer = combineReducers({
  players: PlayersReducer,
  matches: MatchesReducer
})

const store = createStore(
  rootReducer,
  /* preloadedState, */ devToolsEnhancer({})
)

export default store
