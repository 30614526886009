import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, {
  Auth0Client,
  Auth0ClientOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  getIdTokenClaimsOptions,
  RedirectLoginResult,
  IdToken,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,  
} from '@auth0/auth0-spa-js'

interface Auth0User {
  given_name: string
  family_name: string
  nickname: string
  name: string
  picture: string
  locale: 'en'
  updated_at: string
  email: string
  email_verified: boolean
  sub: string
}

interface Auth0ContextTypes {
  isAuthenticated: boolean
  user: Auth0User | undefined
  loading: boolean
  popupOpen: boolean
  loginWithPopup(options: PopupLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<RedirectLoginResult>
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken>
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>
  getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>
  logout(o?: LogoutOptions): void
}
interface Auth0ProviderOptions {
  children: React.ReactElement
  onRedirectCallback: (result: RedirectLoginResult) => void
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<Auth0ContextTypes | null>(null)
export const useAuth0 = () => useContext(Auth0Context)!

type Props = Auth0ProviderOptions & Auth0ClientOptions

export const Auth0Provider: React.FC<Props> = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState<Auth0User>()
  const [auth0Client, setAuth0] = useState<Auth0Client>()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        setUser(user)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (o: PopupLoginOptions) => {
    setPopupOpen(true)
    try {
      await auth0Client!.loginWithPopup(o)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client!.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    const result = await auth0Client!.handleRedirectCallback()
    const user = await auth0Client!.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
    return result
  }

  const providerValues = {
    isAuthenticated,
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) =>
      auth0Client!.getIdTokenClaims(o),
    loginWithRedirect: (o: RedirectLoginOptions) =>
      auth0Client!.loginWithRedirect(o),
    getTokenSilently: (o: GetTokenSilentlyOptions | undefined) =>
      auth0Client!.getTokenSilently(o),
    getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) =>
      auth0Client!.getTokenWithPopup(o),
    logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
  }

  return (
    <Auth0Context.Provider value={providerValues}>
      {children}
    </Auth0Context.Provider>
  )
}

Auth0Provider.defaultProps = {
  onRedirectCallback: DEFAULT_REDIRECT_CALLBACK,
}
