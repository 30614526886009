import React from 'react'
import { useSelector } from 'react-redux'
import { map, size, mean, findIndex, sortBy, reverse } from 'lodash/fp'
import { useTransition, animated, interpolate } from 'react-spring'

// Redux
import { AppStore } from '../../store'
import { Player } from 'features/players/types'

// Styling
import './scoreboard.scss'

// Assets
import { ReactComponent as TrophyIcon } from '../../assets/trophy.svg'

export const Scoreboard = () => {
  const players = useSelector((store: AppStore) => store.players)
  const sortedPlayers = reverse(sortBy((player) => player.rating, players))

  let height = 0
  let rowHeight = 64

  const transitions = useTransition(
    map(
      (player) => ({ ...player, y: (height += rowHeight) - rowHeight }),
      sortedPlayers
    ),
    (player) => player.id,
    {
      from: ({ y }) => ({ y, height: 0, opacity: 0 }),
      leave: ({ y }) => ({ y, height: 0, opacity: 0 }),
      enter: ({ y }) => ({ y, height: rowHeight, opacity: 1 }),
      update: ({ y }) => ({ y, height: rowHeight }),
    }
  )

  return (
    <div className={'scoreboard shadow-sm'}>
      <Header />
      <div className="scoreboard__players" style={{ height }}>
        {transitions.map(({ item, props, key }, index) => {
          const { y, ...rest } = props as any
          const position = findIndex((p) => item.id === p.id, sortedPlayers) + 1
          const style = {
            zIndex: size(players) - index,
            transform: interpolate(
              [y],
              (y: number) => `translate3d(0,${y}px,0)`
            ),
            ...rest,
          }

          return (
            <animated.div key={key} className="scoreboard__card" style={style}>
              <PlayerComponent
                player={item}
                position={position}
                key={item.id}
              />
            </animated.div>
          )
        })}
      </div>
      <Stats players={players} />
    </div>
  )
}

interface PlayerProps {
  players: Player[]
}

export const Header: React.FC = () => {
  return (
    <div className="scoreboard__header row">
      <div className={'header-position cell'}>Pos.</div>
      <div className={'header-name cell'}>Player</div>
      <div className={'header-rating cell'}>Rating</div>
      <div className={'header-matches cell'}>Matches</div>
    </div>
  )
}

export const Stats: React.FC<PlayerProps> = ({ players }) => {
  const playerCount = size(players)
  const playersRating = map((player) => player.rating, players)
  const avarageRating = Math.round(mean(playersRating))

  return (
    <div className="scoreboard__stats">
      <div className={'stats-number'}>No. players: {playerCount}</div>
      <div className={'stats-avarage'}>Avg. rating: {avarageRating}</div>
    </div>
  )
}

interface PlayerComponentProps {
  player: Player
  position: number
}

export const PlayerComponent: React.FC<PlayerComponentProps> = ({
  player,
  position,
}) => {
  return (
    <div className="scoreboard__player row">
      <div className="player-position cell">
        {position === 1 ? <TrophyIcon /> : position}
      </div>
      <div className="player-name cell cell--bold">
        <img
          src={player.picture}
          alt={player.name}
          className={'player-avatar'}
        />
        {player.name}
      </div>
      <div className="player-rating cell">{player.rating}</div>
      <div className="player-matches cell">{player.matches}</div>
    </div>
  )
}
