import { size, map, set, concat } from 'lodash/fp'
import { Player } from '../features/players/types'

/**
 * Based upon Node Arpad
 * @see https://github.com/tlhunter/node-arpad
 */

export enum OUTCOMES {
    'lost' = 0,
    'tied' = 0.5,
    'won' = 1
};

type KFactor = number

export class Elo {

    /**
    * This is some magical constant used by the ELO system
    * It is a score estimation parameter represents the ELO point differential for a player that is 10x better than another player 
    * meaning that a player with an ELO of 1400 is 10x better than a 1000 ELO player.
    * @link http://en.wikipedia.org/wiki/Elo_rating_system#Performance_rating
    */
    private PERF = 400

    private _kFactor: KFactor // The maximum rating change, defaults to 32
    private _minimum: number // The minimum value a calculated rating can be
    private _maximum: number // Integer The maximum value a calculated rating can be

    constructor(kFactor?: KFactor, min?: number, max?: number) {
        this._kFactor = typeof kFactor !== 'undefined' ? kFactor : 32;
        this._minimum = typeof min !== 'undefined' ? min : -Infinity;
        this._maximum = typeof max !== 'undefined' ? max : Infinity;
    }

    get minimum() {
        return this._minimum;
    }

    set minimum(minimum: number) {
        this._minimum = minimum;
    }

    get maximum() {
        return this._maximum;
    }

    set maximum(maximum: number) {
        this._maximum = maximum;
    }

    /**
     * When setting the K-factor, you can do one of three things.
     * Provide a falsey value, and we'll default to using 32 for everything.
     * Provide a number, and we'll use that for everything.
     * Provide an object where each key is a numerical lower value.
     *
     * @arg {Number|Object} kFactor The K-factor to use
     */

    setKFactor(factor?: KFactor) {
        this._kFactor = factor || 32;
    }

    /**
     * Returns the K-factor depending on the provided rating
     *
     * @return {Number} The determined K-factor, e.g. 32
     */

    getkFactor() {
        return this._kFactor;
    }

    /**
     * Determines the expected "score" of a match
     *
     * @param {Number} rating The rating of the person whose expected score we're looking for, e.g. 1200
     * @param {Number} opponent_rating the rating of the challening person, e.g. 1200
     * @return {Number} The score we expect the person to recieve, e.g. 0.5
     *
     * @link http://en.wikipedia.org/wiki/Elo_rating_system#Mathematical_details
     */

    getExpectedScore(rating: number, opponentRating: number): number {
        var difference = opponentRating - rating;
        return 1 / (1 + Math.pow(10, difference / this.PERF));
    };

    /**
     * Returns an array of anticipated scores for both Player
     *
     * @param {Number} player_1_rating The rating of player 1, e.g. 1200
     * @param {Number} player_2_rating The rating of player 2, e.g. 1200
     * @return {Array} The anticipated scores, e.g. [0.25, 0.75]
     */
    bothExpectedScores(playerOneRating: number, playerTwoRating: number): number[] {
        return [
            this.getExpectedScore(playerOneRating, playerTwoRating),
            this.getExpectedScore(playerTwoRating, playerOneRating)
        ];
    };

    /**
     * The calculated new rating based on the expected outcone, actual outcome, and previous score
     *
     * @param {Number} expectedScore The expected score, e.g. 0.25
     * @param {Number} actualScore The actual score, e.g. 1
     * @param {Number} previousRating The previous rating of the player, e.g. 1200
     * @return {Number} The new rating of the player, e.g. 1256
     */
    newRating(expectedScore: number, actualScore: number, previousRating: number): number {
        var difference = actualScore - expectedScore;
        var rating = Math.round(previousRating + this._kFactor * difference);
        if (rating < this.minimum) {
            rating = this.minimum;
        } else if (rating > this.maximum) {
            rating = this.maximum;
        }
        return rating;
    }

    /**
     * Calculates a new rating from an existing rating and opponents rating if the player won
     *
     * This is a convenience method which skips the score concept
     *
     * @param {Number} rating The existing rating of the player, e.g. 1200
     * @param {Number} opponentRating The rating of the opponent, e.g. 1300
     * @return {Number} The new rating of the player, e.g. 1300
     */
    newRatingIfWon(rating: number, opponentRating: number): number {
        var odds = this.getExpectedScore(rating, opponentRating);
        return this.newRating(odds, OUTCOMES.won, rating);
    }

    /**
     * Calculates a new rating from an existing rating and opponents rating if the player lost
     *
     * This is a convenience method which skips the score concept
     *
     * @param {Number} rating The existing rating of the player, e.g. 1200
     * @param {Number} opponentRating The rating of the opponent, e.g. 1300
     * @return {Number} The new rating of the player, e.g. 1180
     */
    newRatingIfLost(rating: number, opponentRating: number): number {
        var odds = this.getExpectedScore(rating, opponentRating);
        return this.newRating(odds, OUTCOMES.lost, rating);
    };


    /**
     * Calculates a new rating from an existing rating and opponents rating if the player tied
     *
     * This is a convenience method which skips the score concept
     *
     * @param {Number} rating The existing rating of the player, e.g. 1200
     * @param {Number} opponentRating The rating of the opponent, e.g. 1300
     * @return {Number} The new rating of the player, e.g. 1190
     */
    newRatingIfTied(rating: number, opponentRating: number): number {
        var odds = this.getExpectedScore(rating, opponentRating);
        return this.newRating(odds, OUTCOMES.tied, rating);
    };

    /**
     * Calculate ELO rating for multiplayer
     * 
     * Formula used to calculate rating for Player1 
     * NewRatingP1 = RatingP1 + K * (S - EP1)
     * 
     * Where: 
     * RatingP1 = current rating for Player1 
     * K = K-factor 
     * S = actualScore (1 win, 0 lose) 
     * EP1 = Q1 / Q1 + Q2 + Q3 
     * Q(i) = 10 ^ (RatingP(i)/400)
     * 
     * @param {array} winners An array of players
     * @param {array} losers The array of losing players     
     * @return {object} The results of all the new players where key is player id and value is the new rating
     */

    calculateMultiplayer(winners: Player[], losers: Player[]) {

        if (size(winners) === 0 || size(losers) === 0 ) {
            return null;
        }

        const allPlayers = concat(winners, losers)

        let newUsersPoints: Player[] = []

        const kFactor = this._kFactor;

        // Calculate total Q
        var Q = 0.0;
        map((player) => {
            Q += Math.pow(10.0, (player.rating / this.PERF));
        }, allPlayers)

        // Calculate new rating
        map((player) => {
            /**
             * Expected rating for an user
             * E = Q(i) / Q(total)
             * Q(i) = 10 ^ (R(i)/400)
             */
            var expected = Math.pow(10.0, (player.rating / this.PERF)) / Q;

            /**
             * Actual score is
             * 1 - if player is winner
             * 0 - if player losses
             * (another option is to give fractions of 1/number-of-players instead of 0)
             */
            var actualScore: number;
            if (winners.includes(player)) {
                actualScore = 1;
            } else {
                actualScore = 0;
            }

            // new rating = R1 + K * (S - E);
            const newRating = Math.round(player.rating + kFactor * (actualScore - expected));

            // Add to HashMap            
            const updatedRating = set('rating', newRating, player);
            const updatedPlayer = set('matches', updatedRating.matches + 1, updatedRating); 
            newUsersPoints.push(updatedPlayer);

        }, allPlayers)

        return newUsersPoints;

    }

}

export const MatchSystem = new Elo();