import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'

// Components
import { Button } from 'components/Button'

export const About: React.FC = () => {
  const history = useHistory()

  const handleOnClick = () => {
    history.push('/')
  }

  return (
    <Fragment>
      <h1>About</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas
        possimus doloribus error cumque autem asperiores, ullam deserunt quidem
        omnis doloremque itaque eius eaque sint facilis unde tenetur reiciendis
        aliquam soluta?
      </p>
      <Button type="button" onClick={handleOnClick}>
        Go back
      </Button>
    </Fragment>
  )
}
