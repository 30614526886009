import React, { useEffect } from 'react'

// Components
import { Portal } from 'components/Portal'

import './modal.scss'

interface Props {
  id: string
  title: string
  children: React.ReactNode
  open: boolean
  confirmText?: string
  onClose: () => void
  onConfirm?: () => void
}

export const Modal: React.FC<Props> = ({
  children,
  id,
  title,
  open,
  confirmText,
  onClose,
  onConfirm,
}) => {
  useEffect(() => {
    function setBodyClass() {
      if (open) {
        document.body.classList.add('modal-open')
      } else {
        document.body.classList.remove('modal-open')
      }
    }

    setBodyClass()

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [open])

  return (
    <Portal>
      <div
        className={`modal modal--animate-scale flex flex-center bg-contrast-higher bg-opacity-90% padding-md ${
          open ? 'modal--is-visible' : ''
        }`}
        id={id}
      >
        <div
          className={`modal__content width-100% max-width-sm bg radius-md shadow-md`}
          role="alertdialog"
          aria-labelledby={`modal-title-${id}`}
        >
          <header
            className={`bg-contrast-lower padding-y-sm padding-x-md flex items-center justify-between`}
          >
            <h4 className={`text-truncate`} id={`modal-title-${id}`}>
              {title}
            </h4>
            <button
              className={`reset modal__close-btn modal__close-btn--inner hide@md`}
              onClick={onClose}
            >
              <svg className={`icon`} viewBox="0 0 20 20">
                <title>Close modal window</title>
                <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
                  <line x1="3" y1="3" x2="17" y2="17" />
                  <line x1="17" y1="3" x2="3" y2="17" />
                </g>
              </svg>
            </button>
          </header>

          <div className={`padding-y-sm padding-x-md`}>{open ? children : null}</div>

          {onConfirm && (
            <footer className={`padding-md`}>
              <div className={`flex justify-end gap-xs`}>
                <button className={`btn btn--primary`} onClick={onConfirm}>
                  {confirmText ? confirmText : 'Confirm'}
                </button>
              </div>
            </footer>
          )}
        </div>

        <button
          className={`reset modal__close-btn modal__close-btn--outer display@md`}
          onClick={onClose}
        >
          <svg className={`icon icon--sm`} viewBox="0 0 24 24">
            <title>Close modal window</title>
            <g fill="none" stroke="currentColor" strokeMiterlimit="10" strokeWidth="2">
              <line x1="3" y1="3" x2="21" y2="21" />
              <line x1="21" y1="3" x2="3" y2="21" />
            </g>
          </svg>
        </button>
      </div>
    </Portal>
  )
}
