import React, { useState, useEffect, useMemo } from 'react'
import { map, find } from 'lodash/fp'

// Utils
import { MatchSystem } from '../../utils/Elo'

// Redux
import { Player, PlayersState } from '../../features/players/types'

// Styling
import './add-match-form.scss'
import { Button } from 'components/Button'

const initialState = {
  playerOne: '',
  playerTwo: '',
  playerThree: '',
  playerFour: '',
}

interface Props {
  availablePlayers: PlayersState
  onResults: (results: Player[]) => void
}

export const AddMatchForm: React.FC<Props> = ({ availablePlayers, onResults }) => {
  const [type, setType] = useState<string>('2vs2')
  const [form, setForm] = useState(initialState)
  const [playerResults, setPlayerResults] = useState<Player[]>([])

  useEffect(() => {
    function reset() {
      setForm(initialState)
      setType('2vs2')
      setPlayerResults([])
    }

    reset()

    return () => {
      reset()
    }
  }, [])

  useMemo(() => {
    const calculateRatings = (form: typeof initialState) => {
      let results: Player[] = []

      // Find each player
      const playerOne = find((player) => player.email === form.playerOne, availablePlayers)
      const playerTwo = find((player) => player.email === form.playerTwo, availablePlayers)
      const playerThree = find((player) => player.email === form.playerThree, availablePlayers)
      const playerFour = find((player) => player.email === form.playerFour, availablePlayers)

      // Check if it was single match (1vs1)
      if (type === '1vs1' && playerOne && playerThree) {
        const winners = [playerOne]
        const losers = [playerThree]
        const singleResult = MatchSystem.calculateMultiplayer(winners, losers)
        if ( singleResult !== null ) {
          results = singleResult;
        }
      }

      // Check if it was a double (2vs2)
      if (type === '2vs2' && playerOne && playerTwo && playerThree && playerFour) {
        const winners = [playerOne, playerTwo]
        const losers = [playerThree, playerFour]
        const doubleResults = MatchSystem.calculateMultiplayer(winners, losers)
        if ( doubleResults !== null ) {
          results = doubleResults;
        }
      }

      return results
    }

    const results = calculateRatings(form)

    if ( results.length > 0) {
      setPlayerResults(results);
    } 

  }, [form, availablePlayers, type])

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }

  const handleOnRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value)
    setPlayerResults([])
    setForm(initialState)
  }

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    onResults(playerResults)
    reset()
  }

  const reset = () => {
    setForm(initialState)
    setType('2vs2')
    setPlayerResults([])
  }

  return (
    <form onSubmit={handleOnSubmit} className={'addMatchForm'}>
      <div className="padding-sm margin-bottom-md flex justify-center items-center">
        <div className="btns btns--radio">
          <div>
            <input
              type="radio"
              id="radio-2vs2"
              name="switch-one"
              value="2vs2"
              checked={type === '2vs2'}
              onChange={handleOnRadioChange}
            />
            <label htmlFor="radio-2vs2" className={'btns__btn reset'}>
              Double
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="radio-1vs1"
              name="switch-one"
              value="1vs1"
              checked={type === '1vs1'}
              onChange={handleOnRadioChange}
            />
            <label htmlFor="radio-1vs1" className={'btns__btn reset'}>
              Single
            </label>
          </div>
        </div>
      </div>

      <div className="grid gap-md gap-lg@md">
        <div className="col col-6@md">
          <h3 className={'text-sm margin-bottom-sm'}>Winners</h3>
          <div className="form-group">
            <label className={'form-label'}>{'Player One'}</label>
            <select
              name={'playerOne'}
              onChange={handleOnChange}
              value={form.playerOne}
              className={'form-control'}
            >
              <option value={''} disabled>
                {'Select player'}
              </option>
              {map((player) => {
                const alreadyChoosen = find((email) => email === player.email, form)
                return (
                  <option
                    key={player.id}
                    value={player.email}
                    disabled={alreadyChoosen !== undefined}
                  >
                    {player.name}
                  </option>
                )
              }, availablePlayers)}
            </select>
          </div>
          {type === '2vs2' && (
            <div className="form-group">
              <label className={'form-label'}>{'Player Two'}</label>
              <select
                name={'playerTwo'}
                onChange={handleOnChange}
                value={form.playerTwo}
                className={'form-control'}
              >
                <option value={''} disabled>
                  {'Select player'}
                </option>
                {map((player) => {
                  const alreadyChoosen = find((email) => email === player.email, form)
                  return (
                    <option
                      key={player.id}
                      value={player.email}
                      disabled={alreadyChoosen !== undefined}
                    >
                      {player.name}
                    </option>
                  )
                }, availablePlayers)}
              </select>
            </div>
          )}
        </div>
        <div className="col col-6@md">
          <h3 className={'text-sm margin-bottom-sm'}>Losers</h3>
          <div className="form-group">
            <label className={'form-label'}>
              {type === '1vs1' ? 'Player Two' : 'Player Three'}
            </label>
            <select
              name={'playerThree'}
              onChange={handleOnChange}
              value={form.playerThree}
              className={'form-control'}
            >
              <option value={''} disabled>
                {'Select player'}
              </option>
              {map((player) => {
                const alreadyChoosen = find((email) => email === player.email, form)
                return (
                  <option
                    key={player.id}
                    value={player.email}
                    disabled={alreadyChoosen !== undefined}
                  >
                    {player.name}
                  </option>
                )
              }, availablePlayers)}
            </select>
          </div>
          {type === '2vs2' && (
            <div className="form-group">
              <label className={'form-label'}>{'Player Four'}</label>
              <select
                name={'playerFour'}
                onChange={handleOnChange}
                value={form.playerFour}
                className={'form-control'}
              >
                <option value={''} disabled>
                  {'Select player'}
                </option>
                {map((player) => {
                  const alreadyChoosen = find((email) => email === player.email, form)
                  return (
                    <option
                      key={player.id}
                      value={player.email}
                      disabled={alreadyChoosen !== undefined}
                    >
                      {player.name}
                    </option>
                  )
                }, availablePlayers)}
              </select>
            </div>
          )}
        </div>
      </div>

      {playerResults.length > 0 && (
        <table className={'width-100% table margin-top-sm'}>
          <thead>
            <tr>
              <td className={'padding-xs'}>
                <span className="text-sm font-semibold">Name</span>
              </td>
              <td className={'padding-xs'}>
                <span className="text-sm font-semibold">Previous Rating</span>
              </td>
              <td className={'padding-xs'}>
                <span className="text-sm font-semibold">New Rating</span>
              </td>
              <td className={'padding-xs'}>
                <span className="text-sm font-semibold">Change</span>
              </td>
            </tr>
          </thead>
          <tbody>
            {map((player) => {
              const previousRating = availablePlayers[player.id].rating
              const diff = player.rating - previousRating
              const isPositive = diff > 0
              return (
                <tr
                  key={player.id}
                  className={'player-results text-sm border-bottom border-contrast-lower'}
                >
                  <td className={'padding-xs'}>
                    <div className="flex items-center">
                      <figure className="width-md height-md radius-50% flex-shrink-0 overflow-hidden margin-right-xs">
                        <img
                          className="block width-100% height-100% object-cover"
                          src={player.picture}
                          alt="Player"
                        />
                      </figure>

                      <div className="line-height-xs">
                        <p>{player.name}</p>
                      </div>
                    </div>
                  </td>
                  <td className={'padding-xs'}>{previousRating}</td>
                  <td className={'padding-xs'}>{player.rating}</td>
                  <td className={'padding-xs'}>
                    <span className={isPositive ? 'color-success' : 'color-error'}>
                      {isPositive ? `+${diff}` : `${diff}`}
                    </span>
                  </td>
                </tr>
              )
            }, playerResults)}
          </tbody>
        </table>
      )}

      <div className={'width-100% flex justify-end margin-top-md'}>
        <Button onClick={handleOnSubmit} type={'submit'} className={'btn--primary'}>
          {'Save match'}
        </Button>
      </div>
    </form>
  )
}
