import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { map } from 'lodash/fp'

// Components
import { Modal } from '../components/Modal'
import { Scoreboard } from '../components/Scoreboard'
import { AddMatchForm } from '../components/AddMatchForm'
import { Button } from '../components/Button'

// Styling
import './home.scss'

// Features
import { Player } from '../features/players/types'
import { selectors } from '../features/players'

// Utils
import { MatchesDatabase, PlayersDatabase } from '../services/firebase'
import { useAuth0 } from '../auth0'

export const Home: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const players = useSelector(selectors.getPlayers)
  const [modalOpen, setModalVisibility] = useState(false)

  const handleOnOpen = () => {
    setModalVisibility(true)
  }

  const handleOnClose = () => {
    setModalVisibility(false)
  }

  const handleOnSave = (results: Player[]) => {
    setModalVisibility(false)

    // Update each player in the database
    map((player) => {
      PlayersDatabase.doc(player.id)
        .set({ ...player }, { merge: true })
        .catch(() => {
          alert('Ops something went wrong when trying to update the scores')
        })
    }, results)

    // Add new match
    MatchesDatabase.add({
      id: '(not set)',
      createdAt: '(not set)',
      results: results,
    }).then((ref) => {
      ref.update({ id: ref.id, createdAt: new Date().toISOString() })
    })
  }

  return (
    <div className={'home-page bg-contrast-lower'}>
      <div className={'container max-width-adaptive-sm padding-y-md padding-y-lg@md'}>
        <Scoreboard />
        {isAuthenticated && (
          <Button onClick={handleOnOpen} className={'btn--primary width-100% margin-top-sm'}>
            Add match
          </Button>
        )}
      </div>
      <Modal
        id={'add-match'}
        onClose={handleOnClose}
        open={modalOpen}
        title="Add match"
        confirmText={'Add match'}
      >
        <AddMatchForm availablePlayers={players} onResults={handleOnSave} />
      </Modal>
    </div>
  )
}
