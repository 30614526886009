
import React from "react";
import { useAuth0 } from '../auth0';

export const ProfileIcon: React.FC = () => {
    const { loading, user } = useAuth0();

    if (loading || !user) {
        return <div>Loading...</div>;
    }

    return (
        <img src={user.picture} alt={user.name} width={96} height={96} className={'profile-icon'} />
    );
};
