import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

// Use a ternary operator to make sure that the document object is defined
const portalRoot = document.getElementById('portal')

interface Props {
    children: React.ReactNode
}

export const Portal: React.FC<Props> = ({ children }) => {
    const mountNode = useRef<HTMLDivElement>(document.createElement('div'))

    useEffect(() => {   
        
        const node = mountNode.current
        
        if ( portalRoot !== null ) {
            portalRoot.appendChild(node)
        }
        

        return () => {
            if (portalRoot) {
                portalRoot.removeChild(node)
            }
        }
    }, [])

    if ( mountNode.current === null ) {
        return null;
    }

    return createPortal(children, mountNode.current)
    
}