import React from 'react'

import './button.scss';

interface Props {
    children: React.ReactNode | React.ReactNode[]
    className?: string,
    type?: 'button' | 'submit' | 'reset'
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const Button: React.FC<Props> = ({ type = 'button', children, onClick, className }) => {
    return (
        <button type={type} className={`btn ${className}`} onClick={onClick}>{children}</button>
    )
}