import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import history from './utils/history'

// Auth0
import { Auth0Provider } from './auth0';
import config from './auth-config.json';

// Redux
import store from './store'

// Firebase
import './services/firebase';

// Styling
import './style/index.scss'

// Components
import App from './App'

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
)
